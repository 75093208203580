import React from "react";
import Header from "../components/header/header.js";
import WhyBAMBanner from "../assets/img/svg/why-bam.svg";
import Brush from "../assets/img/svg/plugIcon/brush.svg";
import CheckIn from "../assets/img/svg/plugIcon/checkin.svg";
import Dynamic from "../assets/img/svg/plugIcon/dynamic.svg";
import Integration from "../assets/img/svg/plugIcon/integration.svg";
import Multilang from "../assets/img/svg/plugIcon/multilang.svg";
import Rate from "../assets/img/svg/plugIcon/rate.svg";
import Reliability from "../assets/img/svg/plugIcon/reliability.svg";
import Responsive from "../assets/img/svg/plugIcon/responsive.svg";
import Search from "../assets/img/svg/plugIcon/search.svg";
import Usability from "../assets/img/svg/plugIcon/usability.svg";
import Wrench from "../assets/img/svg/plugIcon/wrench.svg";

const ADVANTAGES = [
  {
    image: Brush,
    title: `Grafica intuitiva`,
    body: `Back office ben strutturato e di semplice utilizzo.`,
  },
  {
    image: Dynamic,
    title: `Dinamicità`,
    body: `Possibilità di creare offerte personalizzate, anche giornaliere.`,
  },
  {
    image: Responsive,
    title: `Responsive`,
    body: `Grafica ottimizzata per tutti i dispositivi mobili e desktop sia lato operativo che end user.`,
  },
  {
    image: Multilang,
    title: `Sistema multilingue`,
    body: `Italiano, inglese, tedesco, francese, russo, spagnolo, portoghese
    Book@Me parlerà con il tuo cliente in base alla lingua impostata sul browser.`,
  },
  {
    image: Usability,
    title: `Fruibilità`,
    body: `Un sistema integrato e di semplice utilizzo per gli operatori e per gli ospiti della tua struttura ricettiva.`,
  },
  {
    image: CheckIn,
    title: `CheckIN Online`,
    body: `Agevola il lavoro degli operatori e snellisce la prenotazione da parte del cliente.`,
  },
  {
    image: Integration,
    title: `Integrazione`,
    body: `Ampia disponibilità all’integrazione con software gestionali tramite lo sviluppo di API dedicate.`,
  },
  {
    image: Reliability,
    title: `Personalizzazione ed affidabilità`,
    body: `La grafica essenziale ed intuitiva, la sua personalizzazione a livello cromatico per ricordare il sito della struttura abbinata, non confonde il cliente finale e rende agevole la visualizzazione delle offerte.`,
  },
  {
    image: Search,
    title: `Meta search`,
    body: `Possibilità di integrazione Google Analytics, Google ads, Facebook pixel.`,
  },
  {
    image: Rate,
    title: `Rate Comparison`,
    body: `È uno strumento intuitivo che consente alle strutture di monitorare le tariffe e le soluzioni proposte dai concorrenti selezionati che utilizzano portali di prenotazione online.`,
  },
  {
    image: Wrench,
    title: `Monitoraggio 24/7`,
    body: `Un controllo costante delle risorse in uso e di eventuali mal funzionamenti
    Un team di tecnici esperti sarà a tua disposizione per ogni necessità.`,
  },
];

const WhyBAM = () => {
  return (
    <>
      <Header
        title="Perché Book@Me"
        description={`Le prenotazioni dirette sono più vantaggiose per la tua attività, al tempo stesso le OTA sono essenziali per darle la giusta visibilità.`}
        categoryIcon={{ icon: WhyBAMBanner, alt: "Why-BAM Banner" }}
        metaDescription="Le prenotazioni dirette sono più vantaggiose per la tua attività, al tempo stesso le OTA sono essenziali per darle la giusta visibilità."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <span className="green bold">
              Lo sapevi che il 90% degli hotel dipende in maniera eccessiva
              dalle OTA? Mantieni un costante monitoraggio per evitare che ti
              sottraggano clienti diretti.
            </span>
            <p className="description">
              <br />
              <br />
              Con Book@Me potrai scegliere di gestire tariffe e creare offerte
              vantaggiose in determinati periodi dell’anno, stimolando e
              favorendo le prenotazioni direttamente sul sito della tua
              struttura.
              <br />
              <br />
              <span className="green bold">
                Il risultato?
                <br /> Moltiplicherai i tuoi clienti!
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <div className="container">
        {ADVANTAGES.map((i, index) => {
          let j = index !== null ? ADVANTAGES[index + 1] : null;
          if (index % 2 === 0) {
            return (
              <div className="columns" key={index}>
                <div className="column is-5">
                  <div className="section" />
                  <img src={i.image} alt="" />
                  <br />
                  <span className="green bold">{i.title}</span>
                  <br />
                  <p>{i.body}</p>
                </div>
                {j ? (
                  <div className="column is-offset-2">
                    <div className="section" />
                    <img src={j.image} alt="" />
                    <br />
                    <span className="green bold">{j.title}</span>
                    <br />
                    <p>{j.body}</p>
                  </div>
                ) : null}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className="section" />
    </>
  );
};

export default WhyBAM;
